<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="adminData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching admin data
      </h4>
      <div class="alert-body">
        No admin found with this admin id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'master-admins-list' }"
        >
          Admin List
        </b-link>
        for other admins.
      </div>
    </b-alert>

    <template v-if="adminData">
      <!-- First Row -->
      <b-row>
        <b-col>
          <admin-member-view-admin-info-card :admin-data="adminData.data" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <!-- <admin-view-admin-timeline-card /> -->
        </b-col>
        <b-col cols="12">
          <admin-member-view-admin-permissions-card
            :ability="adminData.data.role.ability"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import adminStoreModule from '../adminMemberStoreModule'
import AdminMemberViewAdminInfoCard from './AdminMemberViewAdminInfoCard.vue'
// import AdminViewAdminPlanCard from './AdminViewAdminPlanCard.vue'
// import AdminViewAdminTimelineCard from './AdminViewAdminTimelineCard.vue'
import AdminMemberViewAdminPermissionsCard from './AdminMemberViewAdminPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    AdminMemberViewAdminInfoCard,
    // AdminViewAdminPlanCard,
    // AdminViewAdminTimelineCard,
    AdminMemberViewAdminPermissionsCard,
  },
  setup() {
    const adminData = ref(null)

    const ADMIN_APP_STORE_MODULE_NAME = 'master-admin'

    // Register module
    if (!store.hasModule(ADMIN_APP_STORE_MODULE_NAME)) store.registerModule(ADMIN_APP_STORE_MODULE_NAME, adminStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ADMIN_APP_STORE_MODULE_NAME)) store.unregisterModule(ADMIN_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('master-admin/fetchAdmin', {
        id: router.currentRoute.params.id,
      })
      .then(response => {
        adminData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          adminData.value = undefined
        }
      })

    return {
      adminData,
    }
  },
}
</script>

<style></style>
