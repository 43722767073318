<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="adminData.avatar"
            :text="avatarText(adminData.name)"
            :variant="`light-${resolveUserRoleVariant(adminData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ adminData.name }}
              </h4>
              <span class="card-text">{{ adminData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                :to="{
                  name: 'master-admin-edit',
                  params: { id: adminData.id },
                }"
                :disabled="!$can('update', 'Admin Members')"
              >
                {{ $t('common.edit') }}
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                :disabled="!$can('delete', 'Admin Members')"
                @click="confirmDelete(adminData)"
              >
                {{ $t('common.delete') }}
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <!-- <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                23.3k
              </h5>
              <small>Monthly Sales</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                $99.87k
              </h5>
              <small>Annual Profit</small>
            </div>
          </div>
        </div> -->
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{
                $t('admin.form.status')
              }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ adminData.isActive ? 'active' : 'inactive' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('admin.form.role') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ adminData.role.name }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import useUsersList from '@/views/apps/user/users-list/useUsersList'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    adminData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    confirmDelete({ id, name }) {
      this.$swal({
        title: `Delete ${name} ?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        try {
          if (result.value) {
            const res = await store.dispatch('master-admin/deleteAdmin', { id })

            if (res.data.code !== 400) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                timer: 2000,
                text: 'Data has been successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.$router.replace({ name: 'master-admin-member' })
            } else {
              this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            }
          }
        } catch (e) {
          this.$swal({
            title: 'Error!',
            text: 'Something went wrong!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },

  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    const data = {
      id: 21,
      fullName: 'Selina Kyle',
      company: 'Wayne Enterprises',
      role: 'admin',
      username: 'catwomen1940',
      country: 'USA',
      contact: '(829) 537-0057',
      email: 'irena.dubrovna@wayne.com',
      currentPlan: 'team',
      status: 'active',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/1.png'),
    }
    return {
      avatarText,
      data,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style></style>
