<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>{{ $t('admin.tableHeader.permission') }}</b-card-title>
      <b-card-sub-title>Permission according to roles</b-card-sub-title>
    </b-card-body>
    <b-table
      striped
      responsive
      :sticky-header="true"
      :no-border-collapse="true"
      :items="abilityData"
      class="mb-0"
    >

      <template #cell(module)="data">
        {{ $t(data.value) }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  props: {
    ability: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    let abilityData = []

    if (props.ability[0].subject !== 'all') {
      abilityData = props.ability.map(e => {
        const defObj = {
          create: false, read: false, update: false, delete: false,
        }
        const obj = e.action.reduce((acc, cur) => {
          acc[cur] = true
          return acc
        }, {})

        return {
          module: e.subject,
          ...defObj,
          ...obj,
        }
      })
    }

    return {
      abilityData,
    }
  },
}
</script>

<style>

</style>
